import {Datatest} from './datatest.js';
import {Link} from 'react-router-dom';
import { useState,useEffect } from 'react';

export default function Vaskeliste(){
    const [phpData,setphpData] = useState([]);
    useEffect(() => {
        fetch('php/LaundryList.php')
        .then((response) => {
            console.log(response,'hei');
            return response.json()
        }) 
        .then((myJson) =>{
            console.log(myJson,'hallo');
            setphpData(myJson)
        })
        .catch((error)=> {
            console.log(error)
          })
    },[]);
    
    return (
        <>
        <h1>Vaskeliste</h1>
            {phpData.map((data) => {
                console.log('hei')
                return(
                    <div className='vaskOgKnapp'>
                        <div className="tabell">
                        <div className="hvanårhvem">
                        <p className="hva" id="hva">Hva</p>
                        <p className="når">Når</p>
                        <p className="hvem">Hvem</p>
                    </div>
                        <Vask 
                            key={data.row}
                            name={data.name}
                            bestillingtid={data.bestillingtid}
                            typeVask = {data.typeVask}
                        />
                        </div>
                    <button className="button">Meld deg på</button>
                    </div>
                );
            })}
            <h1>Fant du ingen vask som passet?</h1>
            <Link to="/vaskevalg">
	        <button className="bigButton links button">Opprett ny vask</button>
            </Link>
        </>
    );}

function Vask({name,bestillingtid,typeVask}) {
    if(!name) return <div />;
    return (
			<div className="vaskeInfo">
				<p>{typeVask}</p>
				<p>{bestillingtid}</p>
				<p>{name}</p>
			</div>
    );
}

