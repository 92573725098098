/*
export default function Tidvalg() {

    return(
        <>
        <h1>Når vil du vaske?</h1>

        <a href="../timepicker/datetime_time-picker.html">Velg dato</a>
        <div class="tidvalg">
            <input type="datetime-local" name="dato"/>
        </div>


        <div class="navigate">
            <button onclick="" class="tilbake"><a href="vaskevalg.html">Tilbake</a></button>
            <button onclick="" class="ferdig"><a href="overview.html">Ferdig</a></button>
        </div>
        </>
    )
}

<DateTimePicker defaultValue={dayjs('2022-04-17T15:30')} /> 
*/
import {Link} from 'react-router-dom';
import React from 'react';
import { useState } from "react";
import dayjs from 'dayjs';
import axios from 'axios';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

export default function ResponsiveDateTimePickers({typeVask}) {
    const [tid,setTid] = useState(null);

  const handleSubmit = async() =>{
    const formData = {
      bestillingtid: tid,
      typeVask: typeVask
    };
    console.log(formData);
    await axios.post('https://stufprogrammering.no/php/AddLaundryList.php',formData)
    .then(result =>console.log(result))
    .catch((error)=> {
      console.log(error)
    })
  }
  return (
     <>
        <StaticDateTimePicker
            value={tid}
            onChange={(nyTid => setTid(nyTid))}
            onAccept={handleSubmit}
            disablePast={true}
            ampm={false}
            minutesStep={30}
            disableIgnoringDatePartForTimeValidation={true}
            slotProps={{
              actionBar: {
                actions: ['accept'],
              }
            }}
        />
        <button className="tilbake button"><Link to="/vaskevalg">Tilbake</Link></button>
    </>
  );
}



