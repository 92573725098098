import {Link, useNavigate} from "react-router-dom";
import { useState, useRef } from "react";
import axios from "axios";

export default function Innlogging(){
    const [errMsg, setErrMsg] = useState("hei");
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");

    const HandleUsernameChange = (e) => {
        setUsername(e.target.value)
    }
    const HandlePasswordChange = (e) => {
        setPassword(e.target.value)
    }
    
      const handleSubmit = async(e) =>{
        e.preventDefault();
        const formData = {
          username: username,
          password: password
        };
        await axios.post('https://stufprogrammering.no/php/login.php',formData)
        .then(result =>console.log(result))
        .then((myJson) =>{
          console.log(myJson,'hallo');
          setErrMsg("Du er logget inn!")
      })
        .catch((error)=> {
          console.log(error.data)
          setErrMsg("Feil!")
        })
        //navigate("/vaskeliste")
      }


    return (
        <>
        <div className="login">
            <div className="links">
                <Link method="post" className="links active">Logg inn</Link>
                <Link className="links"> Registrer ny bruker </Link>
            </div>
            <form onSubmit={handleSubmit}>
                <input type="text" name="username" value={username} onChange={HandleUsernameChange} placeholder="Brukernavn" reqired/>
                <input type="text" name="password" value={password} onChange={HandlePasswordChange} placeholder="Passord" required/>
                {errMsg && <div className="error">{errMsg}</div>}
                <input type="submit" value="Logg inn"/>
            </form>
        </div> 
     </> );
    };

